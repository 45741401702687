/* custom bootstrap css */

html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}

body > .container {
  padding: 60px 15px 0;
}

.container {
  padding-top: 60px;
}

.toast {
  z-index: 99999;
}
